@import url('https://fonts.googleapis.com/css?family=Gamja+Flower" rel="stylesheet');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@500&family=Square+Peg&display=swap');



.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.background-image {
  background-repeat: repeat;
}

.fade-in {
  transition: opacity 1s ease;
}

.fade-out {
  opacity: 0;
  transition: opacity 1s ease;
}
/* #9fcf6e  #d28492 #5cafff*/
strong {
  color: #5cafff;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.jagged-border {
  background-image:
    /* Top jagged */
    linear-gradient(
        135deg,
        rgba(255,255,255,1) 50%, rgba(255,255,255,0) 50%
    ),
    linear-gradient(
        -135deg,
        rgba(255,255,255,1) 50%, rgba(255,255,255,0) 50%
    ),
    /* Bottom jagged */
    linear-gradient(
        45deg,
        rgba(255,255,255,1) 50%, rgba(255,255,255,0) 50%
    ),
    linear-gradient(
        -45deg,
        rgba(255,255,255,1) 50%, rgba(255,255,255,0) 50%
    );
    background-position:
      /* Top jagged */
      top center, top center,
      /* Bottom jagged */
      bottom center, bottom center;
    background-size:
        /* Top + bottom jagged */
        0.75rem 0.75rem, 0.75rem 0.75rem,
        0.75rem 0.75rem, 0.75rem 0.75rem;
    background-repeat: repeat-x;

}